export const BR_EMAIL = "info@barerock.group";

export const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const submitLoadingMessage =
  "Please wait…. Submitting your application, don’t refresh the page.";

export const loadingGenericMessage =
  "Please wait…. Processing your request, don’t refresh the page.";

export const barerockUrl = "https://apply.barerock.group/";

export const requiredAnswerMessage =
  "Please answer all required questions/fields or use skip for now";

export const errorMessage =
  "We've encountered an unexpected hiccup. Try refreshing your page to try again.";

export const mtaCodes = {
  address: "MTA0001",
  companyName: "MTA0002",
  policyTermLength: "MTA0003",
  natureOfBusiness: "MTA0004",
  insuredName: "MTA0005",
  dbIntroducer: "MTA0006",
  runOff: "MTA0007",
  cancelPolicy: "MTA0008",
  others: "MTA0009",
};

export const mtaMessages = {
  [mtaCodes.address]: "Please enter the new address below.",
  [mtaCodes.companyName]: "Enter your new company name.",
  [mtaCodes.policyTermLength]:
    "Please tell us why you would like to extend the length of your policy with BareRock.",
  [mtaCodes.natureOfBusiness]:
    "What adjustment would you like to make to your policy?",
  [mtaCodes.insuredName]:
    "Enter the name of the business being added to to the policy.",
  [mtaCodes.dbIntroducer]: "Please provide details about the the DB Introducer",
  [mtaCodes.runOff]: "Please provide details about the Runoff below.",
  [mtaCodes.cancelPolicy]:
    "Please tell us why you would like to cancel your policy with BareRock",
  [mtaCodes.others]: "What adjustment would you like to make to your policy?",
};

export const mtaRequiredMessages = {
  type: "Mta type is required",
  date: "Effective date is required",
};

export const allPermissions = {
  CAN_ACCEPT_DECLINE_QUOTE: "can_accept_decline_quote",
  CAN_BIND_QUOTE: "can_bind_quote",
  CAN_DELEGATE_APPLICATION: "can_delegate_application",
  CAN_EDIT_APPLICATION: "can_edit_application",
  CAN_MANAGE_USERS: "can_manage_users",
  CAN_NOTIFY_CLAIM: "can_notify_claim",
  CAN_PAY_PREMIUM: "can_pay_premium",
  CAN_RENEW: "can_renew",
  CAN_START_APPLICATION: "can_start_application",
  CAN_SUBMIT_APPLICATION: "can_submit_application",
  CAN_VIEW_APPLICATION: "can_view_application",
  CAN_VIEW_CLAIMS: "can_view_claims",
  CAN_VIEW_POLICY: "can_view_policy",
  CAN_VIEW_QUOTE: "can_view_quote",
  CAN_VIEW_USERS: "can_view_users",
};

export const thirdPartyPayerReasons = [
  "Cash flow due to temporary financial constraints",
  "Use of a personal account by a sole proprietor",
  "Payment through an associated company or subsidiary",
  "Error in accounting or payment processing",
  "Use of a holding company",
  "Transitional period during business restructuring",
  "Other",
];

export const thirdPartyPayerRelationships = [
  "Director or proprietor’s personal account",
  "Group or Holding Company account",
  "Subsidiary Company account",
  "Associated company account",
  "Other",
];

export const imageTypes = [
  "JPEG",
  "jpeg",
  "jpg",
  "JPG",
  "PNG",
  "png",
  "GIF",
  "gif",
  "SVG",
  "svg",
  "WebP",
  "webp",
];

export const emailType = ["msg", "eml"];

export const videoTypes = [
  "mp4",
  "MP4",
  "avi",
  "AVI",
  "mov",
  "MOV",
  "wmv",
  "WMV",
  "flv",
  "FLV",
  "mkv",
  "MKV",
  "webm",
  "WEBM",
  "m4v",
  "M4V",
  "3gp",
  "3GP",
  "ogg",
  "OGG",
];

export const currentPoliciesStatus = ["PIL", "CAN"];
export const previousPoliciesStatus = ["PED", "PLD", "PRD"];
