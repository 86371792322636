import { Breadcrumbs, Typography, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getSingleMta } from "../../../actions/policy";
import PdfContainer from "../../../components/presentation/common/PdfContainer";
import ArrowLeft from "../../../components/presentation/icons/ArrowLeft";

const MTADocuments = () => {
  const { id, mtaId } = useParams();
  const location = useLocation();
  const fromReview = location?.state?.fromReview;
  const history = useHistory();

  const { singleMta, singleMtaLoading } = useSelector((state) => state.policy);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleMta({ mtaId, policyId: id }));
  }, [mtaId]);

  const mtaDetails = singleMta?.data ?? {};
  const reference = mtaDetails?.mta_reference;

  const mtaDocuments =
    mtaDetails?.policy_documents?.map((item) => item.document) ?? [];
  const additionalDocuments = mtaDetails?.additional_documents ?? [];
  const policyAddendums = mtaDetails?.policy_addendums ?? [];
  const policyBilling = mtaDetails?.policy_billing ?? [];
  const allDocuments = [
    ...mtaDocuments,
    ...additionalDocuments,
    ...policyAddendums,
    ...policyBilling,
  ];

  const handleRoute = () => {
    history.push(`/policy/${id}`);
  };

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }} aria-label="breadcrumb">
        <Link to="/my-policies">My Policies</Link>
        <Typography
          onClick={handleRoute}
          sx={{
            fontWeight: "normal",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color="text.primary"
        >
          {reference}
        </Typography>
        <Typography sx={{ fontWeight: "normal" }} color="text.primary">
          View Documents
        </Typography>
      </Breadcrumbs>
      {fromReview && (
        <Button
          onClick={() => {
            history.push(`/policy/${id}/review-change`);
          }}
          startIcon={<ArrowLeft />}
          variant="text"
          sx={{ color: "#000" }}
        >
          Back
        </Button>
      )}
      <PdfContainer
        allDocuments={allDocuments}
        fromMta={true}
        reference={reference}
        loading={singleMtaLoading}
      />
    </Box>
  );
};

export default MTADocuments;
