import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { MoreActionsPopover } from "@react-pdf-viewer/toolbar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { styled } from "@mui/material/styles";

import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { reactPdfWorkerUrl } from "../../../../config";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { useEffect, useState } from "react";
const PdfViewer = ({ url, name }) => {
  const onDownload = () => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = name ?? "download";
        alink.click();
      });
    });
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "14px",
    },
  }));

  const [zoom, setZoom] = useState(false);

  const padding = "0px 2px";
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ padding }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding, width: "4rem" }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding }}>
              <span style={{ marginLeft: "5px" }}> /</span> <NumberOfPages />
            </div>
            <div style={{ padding }}>
              <GoToNextPage />
            </div>
            <div
              style={{ margin: "auto", display: "flex", alignItems: "center" }}
            >
              <div style={{ padding }}>
                <ZoomOut />
              </div>
              <div style={{ padding }}>
                <Zoom />
              </div>
              <div style={{ padding }}>
                <Zoom>
                  {(props) => <InitialZoom zoom={zoom} props={props} />}
                </Zoom>
              </div>
              <div style={{ padding }}>
                <ZoomIn />
              </div>
            </div>
            <div
              onClick={onDownload}
              style={{ padding, cursor: "pointer", marginTop: "4px" }}
            >
              <BootstrapTooltip arrow title="Download">
                <DownloadForOfflineOutlinedIcon sx={{ fill: "gray" }} />
              </BootstrapTooltip>
            </div>
            <div style={{ padding }}>
              <Print />
            </div>
            <div style={{ padding }}>
              <MoreActionsPopover toolbarSlot={slots} />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (props, e) => renderToolbar(props, e),
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });

  const renderError = () => {
    return "";
  };

  const setOnLoad = () => {
    setZoom((prev) => !prev);
  };

  return (
    <Worker workerUrl={reactPdfWorkerUrl}>
      <Viewer
        onDocumentLoad={setOnLoad}
        fileUrl={url}
        plugins={[defaultLayoutPluginInstance, MoreActionsPopover]}
        renderError={renderError}
      />
    </Worker>
  );
};

export default PdfViewer;

const InitialZoom = ({ props, zoom }) => {
  const handleInitialZoom = () => {
    props.onZoom(1.1);
  };
  useEffect(() => {
    handleInitialZoom();
  }, [zoom]);

  return <></>;
};
