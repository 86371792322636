import { lazy } from "react";
import Loadable from "./Loadable";
import MTADocuments from "../pages/MyPolicies/ReviewPolicyChange/mtaDocuments.js";

const NewApplication = Loadable(lazy(() => import("../pages/NewApplication")));
const MyDashboard = Loadable(lazy(() => import("../pages/MyDashboard")));
const NewUserWelcome = Loadable(
  lazy(() => import("../pages/NewApplication/WelcomeFlow/NewUserWelcome"))
);
const Overview = Loadable(
  lazy(() => import("../pages/NewApplication/WelcomeFlow/Overview"))
);

const IntroductionVideo = Loadable(
  lazy(() => import("../pages/NewApplication/WelcomeFlow/IntroductionVideo"))
);
const ChooseSection = Loadable(
  lazy(() => import("../pages/NewApplication/SectionNavigation/ChooseSection"))
);
const Section = Loadable(
  lazy(() => import("../pages/NewApplication/SectionNavigation/Section.js"))
);
const AnswerQuestion = Loadable(
  lazy(() =>
    import("../pages/NewApplication/SectionNavigation/AnswerQuestion.js")
  )
);
const ApplicationSubmitted = Loadable(
  lazy(() => import("../pages/NewApplication/ApplicationSubmitted.js"))
);
const DominantSection = Loadable(
  lazy(() =>
    import("../pages/NewApplication/SectionNavigation/DominantSection")
  )
);

const MyApplications = Loadable(lazy(() => import("../pages/MyApplications")));
const Application = Loadable(
  lazy(() => import("../pages/MyApplications/Application/"))
);

const MyPolicies = Loadable(lazy(() => import("../pages/MyPolicies")));
const Claims = Loadable(lazy(() => import("../pages/PolicyNotifications")));
const ClaimsNotificationDetails = Loadable(
  lazy(() =>
    import("../pages/PolicyNotifications/claimsNotificationDetails.js")
  )
);
const ClaimNotification = Loadable(
  lazy(() => import("../pages/PolicyNotifications/ClaimNotification"))
);
const ChangeDetails = Loadable(
  lazy(() =>
    import("../pages/PolicyNotifications/ClaimNotification/addClaims/index.js")
  )
);
const PolicyChange = Loadable(
  lazy(() => import("../pages/PolicyNotifications/PolicyChange"))
);
const ReviewPolicyChange = Loadable(
  lazy(() => import("../pages/MyPolicies/ReviewPolicyChange"))
);
const Policy = Loadable(
  lazy(() => import("../pages/MyPolicies/ViewCurrentPolicy"))
);
const policyDocument = Loadable(
  lazy(() => import("../pages/MyPolicies/PolicyDocuments"))
);

const PreviousPolicy = Loadable(
  lazy(() => import("../pages/MyPolicies/ViewPreviousPolicy"))
);
const News = Loadable(lazy(() => import("../pages/News"))); //NEWS ARCHIVE PAGE
const Article = Loadable(lazy(() => import("../pages/News/Article"))); //INDIVIDUAL NEWS ARTICLE PAGE
const ManageUsers = Loadable(lazy(() => import("../pages/ManageUsers")));
const EditUser = Loadable(lazy(() => import("../pages/ManageUsers/EditUser")));
const MyProfile = Loadable(lazy(() => import("../pages/MyProfile")));

const TodoPage = Loadable(lazy(() => import("../pages/MyDashboard/Todo")));
const ApplicationComplete = Loadable(
  lazy(() =>
    import("../pages/NewApplication/SectionNavigation/ApplicationComplete")
  )
);
const ReviewAnswer = Loadable(
  lazy(() => import("../pages/NewApplication/SectionNavigation/ReviewAnswer"))
);

const privateRoute = [
  {
    component: MyDashboard,
    path: "/home",
  },
  {
    component: MyDashboard,
    path: "/my-dashboard",
  },
  {
    component: TodoPage,
    path: "/todo",
  },
  {
    component: MyApplications,
    path: "/my-applications",
  },
  {
    component: Application,
    path: "/my-applications/application/:id",
  },
  {
    component: Policy,
    path: "/policy/:id",
  },
  {
    component: policyDocument,
    path: "/policy/:id/policy-documents",
  },
  {
    component: MTADocuments,
    path: "/policy/:id/mta-documents/:mtaId",
  },
  {
    component: PreviousPolicy,
    path: "/previous-policy/:id",
  },
  {
    component: ReviewPolicyChange,
    path: "/policy/:id/review-change/:mtaId",
  },
  {
    component: MyPolicies,
    path: "/my-policies",
  },
  {
    component: Claims,
    path: "/my-claims",
  },
  {
    component: ClaimsNotificationDetails,
    path: "/my-claims/:id",
  },
  {
    component: ChangeDetails,
    path: "/claim-notification/:id",
  },
  {
    component: PolicyChange,
    path: "/change-policy/:id",
  },
  {
    component: ClaimNotification,
    path: "/claim-notification/",
  },
  {
    component: PolicyChange,
    path: "/change-policy/",
  },
  {
    component: News,
    path: "/news",
  },
  {
    component: Article,
    path: "/news/:slug",
  },
  {
    component: ManageUsers,
    path: "/manage-users",
  },
  {
    component: EditUser,
    path: "/manage-users/edit/:id",
  },
  {
    component: MyProfile,
    path: "/my-profile",
  },

  {
    component: NewUserWelcome,
    path: "/application/",
    header: "application",
  },
  {
    component: NewUserWelcome,
    path: "/application/welcome",
    header: "application",
  },

  // {
  //   component: Introduction,
  //   path: "/application/introduction",
  //   header: "application",
  // },
  {
    component: Overview,
    path: "/application/:applicationId/overview",
    header: "application",
  },
  {
    component: ChooseSection,
    path: "/application/:applicationId/choose",
    header: "application",
  },
  {
    component: DominantSection,
    path: "/application/:applicationId/section-end/:id",
    header: "application",
  },
  {
    component: IntroductionVideo,
    path: "/application/:applicationId/introduction-video",
    header: "application",
  },
  {
    component: Section,
    path: "/application/:applicationId/section/:id",
    header: "application",
  },
  {
    component: AnswerQuestion,
    path: "/application/:applicationId/answer-question/:id",
    header: "application",
  },
  {
    component: ApplicationSubmitted,
    path: "/application/:applicationId/submitted",
    header: "application",
  },
  {
    component: NewApplication,
    path: "/applications/renew-application:id",
    header: "application",
  },
  {
    component: ApplicationComplete,
    path: "/application/:applicationId/application-complete",
    header: "application",
  },
  {
    component: ReviewAnswer,
    path: "/application/:sectionname/review-answer",
  },
];
export default privateRoute;
